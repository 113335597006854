// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NextSeo } from 'next-seo';
import _get from 'lodash/get';

import { withTranslation } from '../../config/i18n';

import storeWrapper from '../../store';
import {
  getConfigPage as getConfigPageBase,
  resetConfigPage as resetConfigPageBase,
} from '../../store/actions/ConfigActions';

import PrivateRoute from '../../components/containers/privateRoute';
import CustomPage from '../../components/layouts/custompage';

import { getWebsiteTitle } from '../../helpers/misc';
import { getValueByLocale } from '../../helpers/i18n';
import { getGenericDocCoverUrl } from '../../helpers/entity/common';
import * as pth from '../../helpers/proptypes';

// =============================
// Page
// =============================

class Page extends PureComponent {
  static propTypes = {
    config: pth.config.isRequired,
    getConfigPage: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    isLogged: PropTypes.bool.isRequired,
    pageData: pth.configPage.isRequired,
    resetConfigPage: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    xHost: PropTypes.string.isRequired,
  };

  static getInitialProps = storeWrapper.getInitialPageProps(store => async ({ query }) => {
    const slug = query.slug.join('/');

    await store.dispatch(getConfigPageBase(slug, _get(query, 'draftToken')));

    return {
      slug,
      namespacesRequired: ['common', 'pages', 'components', 'errors'],
    };
  });

  componentDidUpdate(prevProps) {
    const { slug: prevSlug, isLogged: prevIsLogged } = prevProps;
    const { slug, isLogged, getConfigPage } = this.props;

    if (prevSlug !== slug || prevIsLogged !== isLogged) {
      getConfigPage(slug);
    }
  }

  componentWillUnmount() {
    const { resetConfigPage } = this.props;

    resetConfigPage();
  }

  render() {
    const {
      config,
      pageData,
      i18n: { language },
      slug,
      t,
      xHost,
    } = this.props;

    const pageUrl = `https://${xHost}/page/${slug}`;

    const pageTitle = getWebsiteTitle(
      config,
      getValueByLocale(_get(pageData, 'metaTitles', []), language),
    );
    const pageDescription = getValueByLocale(
      _get(pageData, 'metaDescriptions', []) || [],
      language,
    );

    const pageImage = getGenericDocCoverUrl(
      { image: _get(pageData, 'seoPreview', {}) },
      'original',
    );
    // NOTE: We do not know the width & height of the image here, maybe we'll need to specify it...
    const pageImages = pageImage
      ? [
        {
          url: pageImage,
        },
      ]
      : [];

    return (
      <PrivateRoute>
        <NextSeo
          title={pageTitle}
          description={pageDescription}
          canonical={pageUrl}
          openGraph={{
            type: 'website',
            url: pageUrl,
            title: pageTitle,
            description: pageDescription,
            images: pageImages,
          }}
          noIndex={!_get(pageData, 'enableSeo', false)}
        />
        <CustomPage
          data={pageData}
          t={t}
          withMailchimp={_get(config, 'integrations.mailchimp', false)}
        />
      </PrivateRoute>
    );
  }
}

function mapStateToProps({ core, user, config }) {
  return {
    isLogged: user.isLogged,
    pageData: config.page.data,
    xHost: core.serverContext.xHost,
  };
}

export default compose(
  withTranslation('pages'),
  connect(mapStateToProps, {
    getConfigPage: getConfigPageBase,
    resetConfigPage: resetConfigPageBase,
  }),
)(Page);
